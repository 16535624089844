import { useState } from "react";
import { NumberParam, StringParam, useQueryParam, withDefault } from "use-query-params";
import useHpaOptimizationEnabled from "../../components/WorkloadStatusByNamespaceSection/useHpaOptimizationEnabled";
import { DateType } from "../../utils/typesUtils";
import useFreezeTooltip from "../Analytics/AnalyticsV2/Graphs/hooks/useFreezeTooltip";
import { FrozenTooltipType } from "../Analytics/AnalyticsV2/Graphs/hooks/utils";
import { DEFAULT_OPTION, NUMBER_OF_ELEMENTS_QUERY_PARAM_NAME } from "../Analytics/AnalyticsV2/NumberOfElementsFilter";
import { AggregateBy, useGetAggregationParams } from "../Analytics/AnalyticsV2/SelectAggregationView";
import { getFromDate } from "../Analytics/AnalyticsV2/utils";

const useGetGraphState = () => {
  const [currentEpoch] = useState(Date.now());

  const { tooltipTrigger, disabledZoom, updateActiveTooltips } = useFreezeTooltip({});
  const enableHpaOptimization = useHpaOptimizationEnabled();
  const { aggregationView } = useGetAggregationParams();

  const [fromDate, setFromDate] = useQueryParam("fromDate", withDefault(NumberParam, getFromDate(2, currentEpoch)));
  const [toDate, setToDate] = useQueryParam("toDate", withDefault(NumberParam, currentEpoch));
  const [range, setRange] = useQueryParam("range", withDefault(StringParam, "3d"));
  const [numberOfElements] = useQueryParam(
    NUMBER_OF_ELEMENTS_QUERY_PARAM_NAME,
    withDefault(NumberParam, DEFAULT_OPTION)
  );

  const date: DateType = {
    from: fromDate,
    to: toDate,
    range: range,
  };

  const setDate = (date: DateType) => {
    setFromDate(date.from);
    setToDate(date.to);
    setRange(date.range);
  };

  let frozenAndClickableTab;

  switch (true) {
    case aggregationView === AggregateBy.Workloads:
      frozenAndClickableTab = FrozenTooltipType.FrozenAndClickable;
      break;
    case aggregationView === AggregateBy.Namespaces:
      frozenAndClickableTab = FrozenTooltipType.FrozenAndNotClickable;
      break;
    default:
      frozenAndClickableTab = FrozenTooltipType.Disabled;
  }

  return {
    date,
    setDate,
    numberOfElements,
    aggregationView,
    frozenAndClickableTab,
    enableHpaOptimization,
    tooltipTrigger,
    disabledZoom,
    updateActiveTooltips,
  };
};

export default useGetGraphState;
