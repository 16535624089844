import { LayoutEntry } from "../../components/GridLayout/utils";

export const TROUBLESHOOT_DASHBOARD_CATEGORY = "troubleshoot";

export const GENERAL_SYNC_ID = "general_sync_id";

export type Dashboard = {
  id: string;
  layout: LayoutEntry[];
};

export enum CustomDashboard {
  Performance = "Performance",
  ScaleOpsPerformance = "ScaleOps Performance",
  OverallCosts = "Overall Costs",
  ScaleOpsHealth = "ScaleOps Health",
}

export const READ_ONLY_LAYOUTS: Record<string, LayoutEntry[]> = {
  [CustomDashboard.Performance]: [
    {
      x: 0,
      y: 1,
      i: "WorkloadDisruptionsGraph",
    },
    {
      x: 1,
      y: 3,
      i: "DowntimeGraph",
    },
    {
      x: 0,
      y: 3,
      i: "AutomationEventsGraph",
    },
    {
      x: 1,
      y: 2,
      i: "MemoryUnderProvisionedOnStressedNodesGraph",
    },
    {
      x: 0,
      y: 2,
      i: "CPUUnderProvisionedOnStressedNodesGraph",
    },
    {
      x: 1,
      y: 1,
      i: "OutOfMemoryEventsGraph",
    },
    {
      x: 0,
      y: 0,
      i: "NodeCpuUtilizationGraph",
    },
    {
      x: 1,
      y: 0,
      i: "NodeMemoryUtilizationGraph",
    },
  ],
  [CustomDashboard.ScaleOpsPerformance]: [
    {
      x: 1,
      y: 0,
      i: "WorkloadDisruptionsGraph",
    },
    {
      x: 1,
      y: 1,
      i: "DowntimeEventsGraph",
    },
    {
      x: 0,
      y: 1,
      i: "AutomationEventsGraph",
    },
    {
      x: 0,
      y: 0,
      i: "ScaleOpsOptimizationEvictionsGraph",
    },
  ],
  [CustomDashboard.OverallCosts]: [
    {
      x: 0,
      y: 0,
      i: "ExpensiveGraph",
    },
    {
      x: 1,
      y: 0,
      i: "WastefulGraph",
    },
    {
      x: 0,
      y: 1,
      i: "CPUWastedDueToUnsuggestedPoliciesGraph",
    },
    {
      x: 1,
      y: 1,
      i: "MemoryWastedDueToUnsuggestedPoliciesGraph",
    },
  ],
  [CustomDashboard.ScaleOpsHealth]: [
    {
      x: 0,
      y: 2,
      i: "ScaleOpsCPUUsage",
    },
    {
      x: 1,
      y: 2,
      i: "ScaleOpsMemoryUsage",
    },
    {
      x: 0,
      y: 1,
      i: "ScaleOpsCPURequests",
    },
    {
      x: 1,
      y: 1,
      i: "ScaleOpsMemoryRequests",
    },
    {
      x: 0,
      y: 0,
      i: "IssuesGraph",
    },
    {
      x: 1,
      y: 0,
      i: "VersionGraph",
    },
  ],
};
