// import { GetCategoryTopkQueryKey } from "../../../api/fetcher";
import { getDisplayWorkloadKey } from "../../../utils/namesUtils";
import CategoryTopKMultiLineChart from "../../Analytics/AnalyticsV2/Graphs/CategoryTopKMultiLineChart";
import { AggregateBy } from "../../Analytics/AnalyticsV2/SelectAggregationView";
import useGetGraphState from "../useGetGraphsState";
import { GENERAL_SYNC_ID } from "../utils";
import { capitalizeFirstLetterAndCamelCaseToSnakeSpace, CommonGraphProps, GraphDisplayName } from "./utils";

const DowntimeEventsGraph = ({
  isMulticluster,
  tooltipTrigger,
  disabledZoom,
  updateActiveTooltips,
}: CommonGraphProps) => {
  const { date, setDate, numberOfElements, aggregationView, frozenAndClickableTab } = useGetGraphState();

  return (
    <CategoryTopKMultiLineChart
      title={GraphDisplayName.DowntimeEventsGraph}
      queryParams={{
        from: date.range ? undefined : date.from,
        to: date.range ? undefined : date.to,
        range: date.range,
        // @ts-expect-error this is for testing
        queryKey: "automationSummary",
        //   aggregationView === AggregateBy.Workloads
        //     ? GetCategoryTopkQueryKey.OomOverTimeCauseLimit
        //     : GetCategoryTopkQueryKey.OomOverTimeCauseNodePressure,
      }}
      setDate={setDate}
      isMulticluster={isMulticluster}
      topK={numberOfElements}
      tooltipTrigger={tooltipTrigger}
      disabledZoom={disabledZoom}
      updateActiveTooltips={updateActiveTooltips}
      enableCopyTextOnClick
      dotted={true}
      frozenTooltipType={frozenAndClickableTab}
      syncId={GENERAL_SYNC_ID}
      renderNameFunction={(name) => capitalizeFirstLetterAndCamelCaseToSnakeSpace(name)}
      keyParser={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
      hasLegend
    />
  );
};

export default DowntimeEventsGraph;
